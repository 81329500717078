/*
File generated by js-routes 1.4.9
Based on Rails 5.2.4 routes of Camber::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// abv_calculators => /calculators/abv(.:format)
  // function(options)
  abv_calculators_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calculators",false],[2,[7,"/",false],[2,[6,"abv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account => /accounts/:id(.:format)
  // function(id, options)
  account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_comment => /accounts/:account_id/comments/:id(.:format)
  // function(account_id, id, options)
  account_comment_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_comments => /accounts/:account_id/comments(.:format)
  // function(account_id, options)
  account_comments_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_contact => /account_contacts/:id(.:format)
  // function(id, options)
  account_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_contacts => /account_contacts(.:format)
  // function(options)
  account_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// account_group => /account_groups/:id(.:format)
  // function(id, options)
  account_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_groups => /account_groups(.:format)
  // function(options)
  account_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// account_map_dashboards => /dashboards/account_map(.:format)
  // function(options)
  account_map_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"account_map",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_pinned_comment => /accounts/:account_id/pinned_comments/:id(.:format)
  // function(account_id, id, options)
  account_pinned_comment_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"pinned_comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_pinned_comments => /accounts/:account_id/pinned_comments(.:format)
  // function(account_id, options)
  account_pinned_comments_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"pinned_comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_tag => /account_tags/:id(.:format)
  // function(id, options)
  account_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_tags => /account_tags(.:format)
  // function(options)
  account_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// account_time_windows => /accounts/:account_id/time_windows(.:format)
  // function(account_id, options)
  account_time_windows_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"time_windows",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_type => /account_types/:id(.:format)
  // function(id, options)
  account_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_types => /account_types(.:format)
  // function(options)
  account_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// accounts => /accounts(.:format)
  // function(options)
  accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ach_authorization_account => /accounts/:id/ach_authorization(.:format)
  // function(id, options)
  ach_authorization_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ach_authorization",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// active_accounts_dashboards => /dashboards/active_accounts(.:format)
  // function(options)
  active_accounts_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"active_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// active_ar_import => /ar_imports/:id/active(.:format)
  // function(id, options)
  active_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"active",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// activities_account => /accounts/:id/activities(.:format)
  // function(id, options)
  activities_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// address => /addresses/:id(.:format)
  // function(id, options)
  address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// addresses => /addresses(.:format)
  // function(options)
  addresses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// adjusting_order => /adjusting_orders/:id(.:format)
  // function(id, options)
  adjusting_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// adjusting_orders => /adjusting_orders(.:format)
  // function(options)
  adjusting_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// advanced_update_order => /orders/:id/advanced_update(.:format)
  // function(id, options)
  advanced_update_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"advanced_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_callbacks_postmates => /api/callbacks/postmates(.:format)
  // function(options)
  api_callbacks_postmates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"postmates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_callbacks_shopify_create_order => /api/callbacks/shopify_create_order(.:format)
  // function(options)
  api_callbacks_shopify_create_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"shopify_create_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_callbacks_stripe => /api/callbacks/stripe(.:format)
  // function(options)
  api_callbacks_stripe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_callbacks_workwave => /api/callbacks/workwave(.:format)
  // function(options)
  api_callbacks_workwave_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"workwave",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_product_families_index => /api/product_families/index(.:format)
  // function(options)
  api_product_families_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"product_families",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3 => /api/v3/shopify/courier_labels/:order_id(.:format)
  // function(order_id, options)
  api_v3_path: Utils.route([["order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"shopify",false],[2,[7,"/",false],[2,[6,"courier_labels",false],[2,[7,"/",false],[2,[3,"order_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v3_account_sku => /api/v3/accounts/:account_id/skus/:id(.:format)
  // function(account_id, id, options)
  api_v3_account_sku_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"skus",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v3_account_skus => /api/v3/accounts/:account_id/skus(.:format)
  // function(account_id, options)
  api_v3_account_skus_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"skus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v3_deliveryroute => /api/v3/deliveryroutes/:id(.:format)
  // function(id, options)
  api_v3_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_deliveryroutes => /api/v3/deliveryroutes(.:format)
  // function(options)
  api_v3_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"deliveryroutes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_hot_days_happy_hour => /api/v3/hot_days/happy_hour(.:format)
  // function(options)
  api_v3_hot_days_happy_hour_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"hot_days",false],[2,[7,"/",false],[2,[6,"happy_hour",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_hot_days_subscribe => /api/v3/hot_days/subscribe(.:format)
  // function(options)
  api_v3_hot_days_subscribe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"hot_days",false],[2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_mobile_sessions => /api/v3/mobile/sessions(.:format)
  // function(options)
  api_v3_mobile_sessions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"sessions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_order => /api/v3/orders/:id(.:format)
  // function(id, options)
  api_v3_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_order_payment => /api/v3/orders/:order_id/payments/:id(.:format)
  // function(order_id, id, options)
  api_v3_order_payment_path: Utils.route([["order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v3_order_payments => /api/v3/orders/:order_id/payments(.:format)
  // function(order_id, options)
  api_v3_order_payments_path: Utils.route([["order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v3_payments => /api/v3/payments(.:format)
  // function(options)
  api_v3_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_returnable_skus => /api/v3/returnable_skus(.:format)
  // function(options)
  api_v3_returnable_skus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"returnable_skus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_service_exception => /api/v3/service_exceptions/:id(.:format)
  // function(id, options)
  api_v3_service_exception_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"service_exceptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_service_exception_categories => /api/v3/service_exception_categories(.:format)
  // function(options)
  api_v3_service_exception_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"service_exception_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_service_exception_category => /api/v3/service_exception_categories/:id(.:format)
  // function(id, options)
  api_v3_service_exception_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"service_exception_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_service_exceptions => /api/v3/service_exceptions(.:format)
  // function(options)
  api_v3_service_exceptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"service_exceptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_shopify_available_products => /api/v3/shopify/available_products(.:format)
  // function(options)
  api_v3_shopify_available_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"shopify",false],[2,[7,"/",false],[2,[6,"available_products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_shopify_shipping_rates => /api/v3/shopify/shipping_rates(.:format)
  // function(options)
  api_v3_shopify_shipping_rates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"shopify",false],[2,[7,"/",false],[2,[6,"shipping_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_sms_operator_incoming => /api/v3/sms_operator/incoming(.:format)
  // function(options)
  api_v3_sms_operator_incoming_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"sms_operator",false],[2,[7,"/",false],[2,[6,"incoming",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// approve_order => /orders/:id/approve(.:format)
  // function(id, options)
  approve_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approve_update_adjusting_order => /adjusting_orders/:id/approve_update(.:format)
  // function(id, options)
  approve_update_adjusting_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approve_update_order => /orders/:id/approve_update(.:format)
  // function(id, options)
  approve_update_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approve_update_stock_take => /stock_takes/:id/approve_update(.:format)
  // function(id, options)
  approve_update_stock_take_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ar_aging_dashboards => /dashboards/ar_aging(.:format)
  // function(options)
  ar_aging_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"ar_aging",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ar_import => /ar_imports/:id(.:format)
  // function(id, options)
  ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ar_imports => /ar_imports(.:format)
  // function(options)
  ar_imports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// batch => /batches/:id(.:format)
  // function(id, options)
  batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"batches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// batch_journal_entries => /batch_journal_entries(.:format)
  // function(options)
  batch_journal_entries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"batch_journal_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// batch_journal_entry => /batch_journal_entries/:id(.:format)
  // function(id, options)
  batch_journal_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"batch_journal_entries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// batch_router_order_batches => /order_batches/batch_router(.:format)
  // function(options)
  batch_router_order_batches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"order_batches",false],[2,[7,"/",false],[2,[6,"batch_router",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// batches => /batches(.:format)
  // function(options)
  batches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"batches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// beer_inventory_reports => /reports/beer_inventory(.:format)
  // function(options)
  beer_inventory_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"beer_inventory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bol_order => /orders/:id/bol(.:format)
  // function(id, options)
  bol_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bol",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// book_order => /orders/:id/book(.:format)
  // function(id, options)
  book_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"book",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// book_update_order => /orders/:id/book_update(.:format)
  // function(id, options)
  book_update_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"book_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// build_stock_take => /stock_takes/:id/build(.:format)
  // function(id, options)
  build_stock_take_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"build",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// calculator => /calculators/:id(.:format)
  // function(id, options)
  calculator_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"calculators",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// calculators => /calculators(.:format)
  // function(options)
  calculators_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calculators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// callback_response => /callback_responses/:id(.:format)
  // function(id, options)
  callback_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"callback_responses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// callback_responses => /callback_responses(.:format)
  // function(options)
  callback_responses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callback_responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// calls_account => /accounts/:id/calls(.:format)
  // function(id, options)
  calls_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"calls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// can_zone_accounts_dashboards => /dashboards/can_zone_accounts(.:format)
  // function(options)
  can_zone_accounts_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"can_zone_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_adjusting_order => /adjusting_orders/:id/cancel(.:format)
  // function(id, options)
  cancel_adjusting_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_order => /orders/:id/cancel(.:format)
  // function(id, options)
  cancel_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_stock_take => /stock_takes/:id/cancel(.:format)
  // function(id, options)
  cancel_stock_take_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// charge_order => /orders/:id/charge(.:format)
  // function(id, options)
  charge_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"charge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cities_addresses => /addresses/cities(.:format)
  // function(options)
  cities_addresses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"cities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// comment => /comments/:id(.:format)
  // function(id, options)
  comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// comment_attachments => /comments/:comment_id/attachments(.:format)
  // function(comment_id, options)
  comment_attachments_path: Utils.route([["comment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// comment_comment => /comments/:comment_id/comments/:id(.:format)
  // function(comment_id, id, options)
  comment_comment_path: Utils.route([["comment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// comment_comments => /comments/:comment_id/comments(.:format)
  // function(comment_id, options)
  comment_comments_path: Utils.route([["comment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// comment_tag => /comment_tags/:id(.:format)
  // function(id, options)
  comment_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comment_tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// comment_tags => /comment_tags(.:format)
  // function(options)
  comment_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comment_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// comments => /comments(.:format)
  // function(options)
  comments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// complete_order => /orders/:id/complete(.:format)
  // function(id, options)
  complete_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// comps_and_returns_dashboards => /dashboards/comps_and_returns(.:format)
  // function(options)
  comps_and_returns_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"comps_and_returns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// consolidated_sales_tracker_dashboards => /dashboards/consolidated_sales_tracker(.:format)
  // function(options)
  consolidated_sales_tracker_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"consolidated_sales_tracker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact => /contacts/:id(.:format)
  // function(id, options)
  contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_type => /contact_types/:id(.:format)
  // function(id, options)
  contact_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_types => /contact_types(.:format)
  // function(options)
  contact_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contacts => /contacts(.:format)
  // function(options)
  contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// courier_labels_order => /orders/:id/courier_labels(.:format)
  // function(id, options)
  courier_labels_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"courier_labels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// courtesy_call_list_dashboards => /dashboards/courtesy_call_list(.:format)
  // function(options)
  courtesy_call_list_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"courtesy_call_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_from_csv_deliveryroutes => /deliveryroutes/create_from_csv(.:format)
  // function(options)
  create_from_csv_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"create_from_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_from_csv_stock_takes => /stock_takes/create_from_csv(.:format)
  // function(options)
  create_from_csv_stock_takes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[6,"create_from_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_gso_label_order => /orders/:id/create_gso_label(.:format)
  // function(id, options)
  create_gso_label_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_gso_label",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_postmates_delivery_order => /orders/:id/create_postmates_delivery(.:format)
  // function(id, options)
  create_postmates_delivery_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_postmates_delivery",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_transfer_warehouses => /warehouses/create_transfer(.:format)
  // function(options)
  create_transfer_warehouses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"create_transfer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_viamente_route_deliveryroutes => /deliveryroutes/create_viamente_route(.:format)
  // function(options)
  create_viamente_route_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"create_viamente_route",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_term => /credit_terms/:id(.:format)
  // function(id, options)
  credit_term_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"credit_terms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_terms => /credit_terms(.:format)
  // function(options)
  credit_terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"credit_terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// current_user => /current_user(.:format)
  // function(options)
  current_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"current_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// cx_todo_dashboards => /dashboards/cx_todo(.:format)
  // function(options)
  cx_todo_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"cx_todo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dashboard_deliveryroutes => /deliveryroutes/dashboard(.:format)
  // function(options)
  dashboard_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// date_ar_import => /ar_imports/:id/date(.:format)
  // function(id, options)
  date_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// deliver_deliveryroute => /deliveryroutes/:id/deliver(.:format)
  // function(id, options)
  deliver_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deliver",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delivery_break => /delivery_breaks/:id(.:format)
  // function(id, options)
  delivery_break_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_breaks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delivery_breaks => /delivery_breaks(.:format)
  // function(options)
  delivery_breaks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_breaks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// delivery_holiday => /delivery_holidays/:id(.:format)
  // function(id, options)
  delivery_holiday_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_holidays",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delivery_holidays => /delivery_holidays(.:format)
  // function(options)
  delivery_holidays_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_holidays",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// delivery_router_deliveryroutes => /deliveryroutes/delivery_router(.:format)
  // function(options)
  delivery_router_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"delivery_router",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delivery_router_map_deliveryroutes => /deliveryroutes/delivery_router_map(.:format)
  // function(options)
  delivery_router_map_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"delivery_router_map",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delivery_tags_deliveryroute => /deliveryroutes/:id/delivery_tags(.:format)
  // function(id, options)
  delivery_tags_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delivery_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delivery_time => /delivery_times/:id(.:format)
  // function(id, options)
  delivery_time_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_times",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delivery_time_default => /delivery_time_defaults/:id(.:format)
  // function(id, options)
  delivery_time_default_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_time_defaults",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delivery_time_defaults => /delivery_time_defaults(.:format)
  // function(options)
  delivery_time_defaults_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_time_defaults",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// delivery_times => /delivery_times(.:format)
  // function(options)
  delivery_times_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// delivery_type => /delivery_types/:id(.:format)
  // function(id, options)
  delivery_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delivery_types => /delivery_types(.:format)
  // function(options)
  delivery_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// deliveryroute => /deliveryroutes/:id(.:format)
  // function(id, options)
  deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// deliveryrouter_order => /orders/:id/deliveryrouter(.:format)
  // function(id, options)
  deliveryrouter_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deliveryrouter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// deliveryroutes => /deliveryroutes(.:format)
  // function(options)
  deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_saml_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// distributor => /distributors/:id(.:format)
  // function(id, options)
  distributor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"distributors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// distributors => /distributors(.:format)
  // function(options)
  distributors_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"distributors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// draft_services_dashboards => /dashboards/draft_services(.:format)
  // function(options)
  draft_services_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"draft_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// drive => /drive(.:format)
  // function(options)
  drive_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"drive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// drive_begin_deliveryroute => /deliveryroutes/:id/drive_begin(.:format)
  // function(id, options)
  drive_begin_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drive_begin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drive_current_deliveryroute => /deliveryroutes/:id/drive_current(.:format)
  // function(id, options)
  drive_current_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drive_current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drive_current_show_deliveryroute => /deliveryroutes/:id/drive_current_show(.:format)
  // function(id, options)
  drive_current_show_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drive_current_show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drive_deliveryroute => /deliveryroutes/:id/drive(.:format)
  // function(id, options)
  drive_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drive_end_deliveryroute => /deliveryroutes/:id/drive_end(.:format)
  // function(id, options)
  drive_end_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drive_end",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drivelist_deliveryroutes => /deliveryroutes/drivelist(.:format)
  // function(options)
  drivelist_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"drivelist",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// drivelist_order_current_view_deliveryroute => /deliveryroutes/:id/drivelist_order_current_view(.:format)
  // function(id, options)
  drivelist_order_current_view_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drivelist_order_current_view",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drivelist_order_view_deliveryroute => /deliveryroutes/:id/drivelist_order_view(.:format)
  // function(id, options)
  drivelist_order_view_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drivelist_order_view",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drivelist_orders_deliveryroute => /deliveryroutes/:id/drivelist_orders(.:format)
  // function(id, options)
  drivelist_orders_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drivelist_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// drivelist_picklist_deliveryroute => /deliveryroutes/:id/drivelist_picklist(.:format)
  // function(id, options)
  drivelist_picklist_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"drivelist_picklist",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// duplicates_ar_import => /ar_imports/:id/duplicates(.:format)
  // function(id, options)
  duplicates_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account => /accounts/:id/edit(.:format)
  // function(id, options)
  edit_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account_comment => /accounts/:account_id/comments/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_comment_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_contact => /account_contacts/:id/edit(.:format)
  // function(id, options)
  edit_account_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account_group => /account_groups/:id/edit(.:format)
  // function(id, options)
  edit_account_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account_pinned_comment => /accounts/:account_id/pinned_comments/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_pinned_comment_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"pinned_comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_tag => /account_tags/:id/edit(.:format)
  // function(id, options)
  edit_account_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account_type => /account_types/:id/edit(.:format)
  // function(id, options)
  edit_account_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_accounts_tag => /tags/:id/edit_accounts(.:format)
  // function(id, options)
  edit_accounts_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_address => /addresses/:id/edit(.:format)
  // function(id, options)
  edit_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_adjusting_order => /adjusting_orders/:id/edit(.:format)
  // function(id, options)
  edit_adjusting_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ar_account => /accounts/:id/edit_ar(.:format)
  // function(id, options)
  edit_ar_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_ar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ar_import => /ar_imports/:id/edit(.:format)
  // function(id, options)
  edit_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ar_order => /orders/:id/edit_ar(.:format)
  // function(id, options)
  edit_ar_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_ar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_batch => /batches/:id/edit(.:format)
  // function(id, options)
  edit_batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"batches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_batch_journal_entry => /batch_journal_entries/:id/edit(.:format)
  // function(id, options)
  edit_batch_journal_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"batch_journal_entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_calculator => /calculators/:id/edit(.:format)
  // function(id, options)
  edit_calculator_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"calculators",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_callback_response => /callback_responses/:id/edit(.:format)
  // function(id, options)
  edit_callback_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"callback_responses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_comment => /comments/:id/edit(.:format)
  // function(id, options)
  edit_comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_comment_comment => /comments/:comment_id/comments/:id/edit(.:format)
  // function(comment_id, id, options)
  edit_comment_comment_path: Utils.route([["comment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_comment_tag => /comment_tags/:id/edit(.:format)
  // function(id, options)
  edit_comment_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comment_tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_contact => /contacts/:id/edit(.:format)
  // function(id, options)
  edit_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_contact_type => /contact_types/:id/edit(.:format)
  // function(id, options)
  edit_contact_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_credit_term => /credit_terms/:id/edit(.:format)
  // function(id, options)
  edit_credit_term_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"credit_terms",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_delivery_break => /delivery_breaks/:id/edit(.:format)
  // function(id, options)
  edit_delivery_break_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_breaks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_delivery_holiday => /delivery_holidays/:id/edit(.:format)
  // function(id, options)
  edit_delivery_holiday_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_holidays",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_delivery_time => /delivery_times/:id/edit(.:format)
  // function(id, options)
  edit_delivery_time_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_times",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_delivery_time_default => /delivery_time_defaults/:id/edit(.:format)
  // function(id, options)
  edit_delivery_time_default_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_time_defaults",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_delivery_type => /delivery_types/:id/edit(.:format)
  // function(id, options)
  edit_delivery_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_deliveryroute => /deliveryroutes/:id/edit(.:format)
  // function(id, options)
  edit_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_distributor => /distributors/:id/edit(.:format)
  // function(id, options)
  edit_distributor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"distributors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_freight_shipment => /freight_shipments/:id/edit(.:format)
  // function(id, options)
  edit_freight_shipment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"freight_shipments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_gl => /gls/:id/edit(.:format)
  // function(id, options)
  edit_gl_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_hot_day => /hot_days/:id/edit(.:format)
  // function(id, options)
  edit_hot_day_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hot_days",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_membership => /memberships/:id/edit(.:format)
  // function(id, options)
  edit_membership_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_notification => /notifications/:id/edit(.:format)
  // function(id, options)
  edit_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_order => /orders/:id/edit(.:format)
  // function(id, options)
  edit_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_order_batch => /order_batches/:id/edit(.:format)
  // function(id, options)
  edit_order_batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_batches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_order_comment => /orders/:order_id/comments/:id/edit(.:format)
  // function(order_id, id, options)
  edit_order_comment_path: Utils.route([["order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_order_line_item => /order_line_items/:id/edit(.:format)
  // function(id, options)
  edit_order_line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_line_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_order_type => /order_types/:id/edit(.:format)
  // function(id, options)
  edit_order_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_page => /pages/:id/edit(.:format)
  // function(id, options)
  edit_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_phone_subscription => /phone_subscriptions/:id/edit(.:format)
  // function(id, options)
  edit_phone_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"phone_subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_postoff => /postoffs/:id/edit(.:format)
  // function(id, options)
  edit_postoff_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"postoffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_price => /prices/:id/edit(.:format)
  // function(id, options)
  edit_price_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"prices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_product => /products/:id/edit(.:format)
  // function(id, options)
  edit_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_prospect_source => /prospect_sources/:id/edit(.:format)
  // function(id, options)
  edit_prospect_source_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_sources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_prospect_type => /prospect_types/:id/edit(.:format)
  // function(id, options)
  edit_prospect_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_qr_code => /qr_codes/:id/edit(.:format)
  // function(id, options)
  edit_qr_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_codes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_qr_code_redirect => /qr_code_redirects/:id/edit(.:format)
  // function(id, options)
  edit_qr_code_redirect_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_code_redirects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_report => /reports/:id/edit(.:format)
  // function(id, options)
  edit_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sales_region => /sales_regions/:id/edit(.:format)
  // function(id, options)
  edit_sales_region_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_regions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sales_route => /sales_routes/:id/edit(.:format)
  // function(id, options)
  edit_sales_route_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_routes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_service_exception_category => /service_exception_categories/:id/edit(.:format)
  // function(id, options)
  edit_service_exception_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_exception_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sku => /skus/:id/edit(.:format)
  // function(id, options)
  edit_sku_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"skus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sku_tag => /sku_tags/:id/edit(.:format)
  // function(id, options)
  edit_sku_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sku_tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_statement => /statements/:id/edit(.:format)
  // function(id, options)
  edit_statement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_stock_take => /stock_takes/:id/edit(.:format)
  // function(id, options)
  edit_stock_take_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_tag => /tags/:id/edit(.:format)
  // function(id, options)
  edit_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user => /users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_vehicle => /vehicles/:id/edit(.:format)
  // function(id, options)
  edit_vehicle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"vehicles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_visit => /visits/:id/edit(.:format)
  // function(id, options)
  edit_visit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_visit_comment => /visits/:visit_id/comments/:id/edit(.:format)
  // function(visit_id, id, options)
  edit_visit_comment_path: Utils.route([["visit_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[2,[7,"/",false],[2,[3,"visit_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_warehouse => /warehouses/:id/edit(.:format)
  // function(id, options)
  edit_warehouse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// email_statement_account => /accounts/:id/email_statement(.:format)
  // function(id, options)
  email_statement_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"email_statement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// force_update_deliveryroutes => /deliveryroutes/force_update(.:format)
  // function(options)
  force_update_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"force_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// freight_shipment => /freight_shipments/:id(.:format)
  // function(id, options)
  freight_shipment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"freight_shipments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// freight_shipments => /freight_shipments(.:format)
  // function(options)
  freight_shipments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"freight_shipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// gl => /gls/:id(.:format)
  // function(id, options)
  gl_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// gls => /gls(.:format)
  // function(options)
  gls_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// hot_day => /hot_days/:id(.:format)
  // function(id, options)
  hot_day_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hot_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hot_days => /hot_days(.:format)
  // function(options)
  hot_days_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hot_days",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// idp_destroy_saml_user_session => /users/saml/idp_sign_out(.:format)
  // function(options)
  idp_destroy_saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"idp_sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// impersonate_user => /users/:id/impersonate(.:format)
  // function(id, options)
  impersonate_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// incoming => /incoming(.:format)
  // function(options)
  incoming_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"incoming",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// index_all_products => /products/index_all(.:format)
  // function(options)
  index_all_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"index_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// index_out_of_stock_products => /products/index_out_of_stock(.:format)
  // function(options)
  index_out_of_stock_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"index_out_of_stock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// index_with_info_products => /products/index_with_info(.:format)
  // function(options)
  index_with_info_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"index_with_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inventory_by_warehouse_products => /products/inventory_by_warehouse(.:format)
  // function(options)
  inventory_by_warehouse_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"inventory_by_warehouse",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inventory_product => /products/:id/inventory(.:format)
  // function(id, options)
  inventory_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"inventory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inventory_sku => /skus/:id/inventory(.:format)
  // function(id, options)
  inventory_sku_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"skus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"inventory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inventory_warehouse => /warehouses/:id/inventory(.:format)
  // function(id, options)
  inventory_warehouse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"inventory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invoices_deliveryroute => /deliveryroutes/:id/invoices(.:format)
  // function(id, options)
  invoices_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// isithotinsfnow => /isithotinsfnow(.:format)
  // function(options)
  isithotinsfnow_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"isithotinsfnow",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// items_order_batch => /order_batches/:id/items(.:format)
  // function(id, options)
  items_order_batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_batches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// map_location_deliveryroute => /deliveryroutes/:id/map_location(.:format)
  // function(id, options)
  map_location_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"map_location",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// map_location_gps_deliveryroute => /deliveryroutes/:id/map_location_gps(.:format)
  // function(id, options)
  map_location_gps_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"map_location_gps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mark_as_read_notification => /notifications/:id/mark_as_read(.:format)
  // function(id, options)
  mark_as_read_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mark_as_unread_notification => /notifications/:id/mark_as_unread(.:format)
  // function(id, options)
  mark_as_unread_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_unread",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mass_move_orders => /orders/mass_move(.:format)
  // function(options)
  mass_move_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"mass_move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership => /memberships/:id(.:format)
  // function(id, options)
  membership_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"memberships",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// memberships => /memberships(.:format)
  // function(options)
  memberships_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"memberships",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// merge_account => /accounts/:id/merge(.:format)
  // function(id, options)
  merge_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// metadata_user_session => /users/saml/metadata(.:format)
  // function(options)
  metadata_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// missing_ar_import => /ar_imports/:id/missing(.:format)
  // function(id, options)
  missing_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"missing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// myaccounts => /myaccounts(.:format)
  // function(options)
  myaccounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"myaccounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_account => /accounts/new(.:format)
  // function(options)
  new_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account_comment => /accounts/:account_id/comments/new(.:format)
  // function(account_id, options)
  new_account_comment_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_account_contact => /account_contacts/new(.:format)
  // function(options)
  new_account_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account_group => /account_groups/new(.:format)
  // function(options)
  new_account_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account_pinned_comment => /accounts/:account_id/pinned_comments/new(.:format)
  // function(account_id, options)
  new_account_pinned_comment_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"pinned_comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_account_tag => /account_tags/new(.:format)
  // function(options)
  new_account_tag_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account_type => /account_types/new(.:format)
  // function(options)
  new_account_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_address => /addresses/new(.:format)
  // function(options)
  new_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_adjusting_order => /adjusting_orders/new(.:format)
  // function(options)
  new_adjusting_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_ar_import => /ar_imports/new(.:format)
  // function(options)
  new_ar_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_batch => /batches/new(.:format)
  // function(options)
  new_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"batches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_batch_journal_entry => /batch_journal_entries/new(.:format)
  // function(options)
  new_batch_journal_entry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"batch_journal_entries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_calculator => /calculators/new(.:format)
  // function(options)
  new_calculator_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calculators",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_callback_response => /callback_responses/new(.:format)
  // function(options)
  new_callback_response_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callback_responses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_comment => /comments/new(.:format)
  // function(options)
  new_comment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_comment_comment => /comments/:comment_id/comments/new(.:format)
  // function(comment_id, options)
  new_comment_comment_path: Utils.route([["comment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_comment_tag => /comment_tags/new(.:format)
  // function(options)
  new_comment_tag_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comment_tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_contact => /contacts/new(.:format)
  // function(options)
  new_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_contact_type => /contact_types/new(.:format)
  // function(options)
  new_contact_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_credit_term => /credit_terms/new(.:format)
  // function(options)
  new_credit_term_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"credit_terms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_delivery_break => /delivery_breaks/new(.:format)
  // function(options)
  new_delivery_break_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_breaks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_delivery_holiday => /delivery_holidays/new(.:format)
  // function(options)
  new_delivery_holiday_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_holidays",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_delivery_time => /delivery_times/new(.:format)
  // function(options)
  new_delivery_time_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_times",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_delivery_time_default => /delivery_time_defaults/new(.:format)
  // function(options)
  new_delivery_time_default_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_time_defaults",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_delivery_type => /delivery_types/new(.:format)
  // function(options)
  new_delivery_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delivery_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_deliveryroute => /deliveryroutes/new(.:format)
  // function(options)
  new_deliveryroute_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_distributor => /distributors/new(.:format)
  // function(options)
  new_distributor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"distributors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_freight_shipment => /freight_shipments/new(.:format)
  // function(options)
  new_freight_shipment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"freight_shipments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_from_csv_deliveryroutes => /deliveryroutes/new_from_csv(.:format)
  // function(options)
  new_from_csv_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"new_from_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_from_csv_stock_takes => /stock_takes/new_from_csv(.:format)
  // function(options)
  new_from_csv_stock_takes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[6,"new_from_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_gl => /gls/new(.:format)
  // function(options)
  new_gl_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gls",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_hot_day => /hot_days/new(.:format)
  // function(options)
  new_hot_day_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hot_days",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_membership => /memberships/new(.:format)
  // function(options)
  new_membership_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"memberships",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_notification => /notifications/new(.:format)
  // function(options)
  new_notification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_order => /orders/new(.:format)
  // function(options)
  new_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_order_attachment => /orders/:order_id/attachments/new(.:format)
  // function(order_id, options)
  new_order_attachment_path: Utils.route([["order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_order_batch => /order_batches/new(.:format)
  // function(options)
  new_order_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"order_batches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_order_comment => /orders/:order_id/comments/new(.:format)
  // function(order_id, options)
  new_order_comment_path: Utils.route([["order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_order_line_item => /order_line_items/new(.:format)
  // function(options)
  new_order_line_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"order_line_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_order_payment => /orders/:order_id/payments/new(.:format)
  // function(order_id, options)
  new_order_payment_path: Utils.route([["order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_order_type => /order_types/new(.:format)
  // function(options)
  new_order_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"order_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_page => /pages/new(.:format)
  // function(options)
  new_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_phone_subscription => /phone_subscriptions/new(.:format)
  // function(options)
  new_phone_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"phone_subscriptions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_postoff => /postoffs/new(.:format)
  // function(options)
  new_postoff_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"postoffs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_price => /prices/new(.:format)
  // function(options)
  new_price_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"prices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_product => /products/new(.:format)
  // function(options)
  new_product_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_prospect_source => /prospect_sources/new(.:format)
  // function(options)
  new_prospect_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_prospect_type => /prospect_types/new(.:format)
  // function(options)
  new_prospect_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_qr_code => /qr_codes/new(.:format)
  // function(options)
  new_qr_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_codes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_qr_code_redirect => /qr_code_redirects/new(.:format)
  // function(options)
  new_qr_code_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_code_redirects",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_report => /reports/new(.:format)
  // function(options)
  new_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sales_region => /sales_regions/new(.:format)
  // function(options)
  new_sales_region_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_regions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sales_route => /sales_routes/new(.:format)
  // function(options)
  new_sales_route_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_routes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_saml_user_session => /users/saml/sign_in(.:format)
  // function(options)
  new_saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_service_exception_category => /service_exception_categories/new(.:format)
  // function(options)
  new_service_exception_category_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_exception_categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sku => /skus/new(.:format)
  // function(options)
  new_sku_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"skus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sku_tag => /sku_tags/new(.:format)
  // function(options)
  new_sku_tag_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sku_tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_statement => /statements/new(.:format)
  // function(options)
  new_statement_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_stock_take => /stock_takes/new(.:format)
  // function(options)
  new_stock_take_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_tag => /tags/new(.:format)
  // function(options)
  new_tag_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_vehicle => /vehicles/new(.:format)
  // function(options)
  new_vehicle_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"vehicles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_visit => /visits/new(.:format)
  // function(options)
  new_visit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_visit_comment => /visits/:visit_id/comments/new(.:format)
  // function(visit_id, options)
  new_visit_comment_path: Utils.route([["visit_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[2,[7,"/",false],[2,[3,"visit_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_warehouse => /warehouses/new(.:format)
  // function(options)
  new_warehouse_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notification => /notifications/:id(.:format)
  // function(id, options)
  notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notifications => /notifications(.:format)
  // function(options)
  notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// order => /orders/:id(.:format)
  // function(id, options)
  order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// order_attachments => /orders/:order_id/attachments(.:format)
  // function(order_id, options)
  order_attachments_path: Utils.route([["order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order_batch => /order_batches/:id(.:format)
  // function(id, options)
  order_batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_batches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// order_batches => /order_batches(.:format)
  // function(options)
  order_batches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"order_batches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// order_comment => /orders/:order_id/comments/:id(.:format)
  // function(order_id, id, options)
  order_comment_path: Utils.route([["order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// order_comments => /orders/:order_id/comments(.:format)
  // function(order_id, options)
  order_comments_path: Utils.route([["order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order_complete_deliveryroute => /deliveryroutes/:id/order_complete(.:format)
  // function(id, options)
  order_complete_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"order_complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order_enroute_deliveryroute => /deliveryroutes/:id/order_enroute(.:format)
  // function(id, options)
  order_enroute_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"order_enroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order_line_item => /order_line_items/:id(.:format)
  // function(id, options)
  order_line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_line_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// order_line_items => /order_line_items(.:format)
  // function(options)
  order_line_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"order_line_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// order_payment => /orders/:order_id/payments/:id(.:format)
  // function(order_id, id, options)
  order_payment_path: Utils.route([["order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"order_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// order_start_deliveryroute => /deliveryroutes/:id/order_start(.:format)
  // function(id, options)
  order_start_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"order_start",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order_stop_deliveryroute => /deliveryroutes/:id/order_stop(.:format)
  // function(id, options)
  order_stop_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"order_stop",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order_type => /order_types/:id(.:format)
  // function(id, options)
  order_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// order_types => /order_types(.:format)
  // function(options)
  order_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"order_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// orders => /orders(.:format)
  // function(options)
  orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// orders_account => /accounts/:id/orders(.:format)
  // function(id, options)
  orders_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// orders_invoice => /orders/invoices/:id(.:format)
  // function(id, options)
  orders_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// orders_invoices => /orders/invoices(.:format)
  // function(options)
  orders_invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// page => /pages/:id(.:format)
  // function(id, options)
  page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages => /pages(.:format)
  // function(options)
  pages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// pay_account => /accounts/:id/pay(.:format)
  // function(id, options)
  pay_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pay",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// phone_subscription => /phone_subscriptions/:id(.:format)
  // function(id, options)
  phone_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"phone_subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// phone_subscriptions => /phone_subscriptions(.:format)
  // function(options)
  phone_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"phone_subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// pick_list_lifo_deliveryroute => /deliveryroutes/:id/pick_list_lifo(.:format)
  // function(id, options)
  pick_list_lifo_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pick_list_lifo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// picklist_deliveryroute => /deliveryroutes/:id/picklist(.:format)
  // function(id, options)
  picklist_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"picklist",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// picklist_order => /orders/:id/picklist(.:format)
  // function(id, options)
  picklist_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"picklist",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_account => /accounts/:id/portal(.:format)
  // function(id, options)
  portal_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"portal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// postoff => /postoffs/:id(.:format)
  // function(id, options)
  postoff_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"postoffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// postoffs => /postoffs(.:format)
  // function(options)
  postoffs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"postoffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// price => /prices/:id(.:format)
  // function(id, options)
  price_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"prices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// prices => /prices(.:format)
  // function(options)
  prices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"prices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// product => /products/:id(.:format)
  // function(id, options)
  product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// products => /products(.:format)
  // function(options)
  products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// prospect_source => /prospect_sources/:id(.:format)
  // function(id, options)
  prospect_source_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_sources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// prospect_sources => /prospect_sources(.:format)
  // function(options)
  prospect_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// prospect_type => /prospect_types/:id(.:format)
  // function(id, options)
  prospect_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// prospect_types => /prospect_types(.:format)
  // function(options)
  prospect_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"prospect_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// qr_code => /qr_codes/:id(.:format)
  // function(id, options)
  qr_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_codes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// qr_code_redirect => /qr_code_redirects/:id(.:format)
  // function(id, options)
  qr_code_redirect_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_code_redirects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// qr_code_redirects => /qr_code_redirects(.:format)
  // function(options)
  qr_code_redirects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_code_redirects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// qr_codes => /qr_codes(.:format)
  // function(options)
  qr_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"qr_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// quick_order_edit_order => /orders/:id/quick_order_edit(.:format)
  // function(id, options)
  quick_order_edit_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"quick_order_edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// quick_order_orders => /orders/quick_order(.:format)
  // function(options)
  quick_order_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"quick_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// receipt_order => /orders/:id/receipt(.:format)
  // function(id, options)
  receipt_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// red_tag_order => /orders/:id/red_tag(.:format)
  // function(id, options)
  red_tag_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"red_tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// red_tags_dashboard_dashboards => /dashboards/red_tags_dashboard(.:format)
  // function(options)
  red_tags_dashboard_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"red_tags_dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// red_tags_deliveryroute => /deliveryroutes/:id/red_tags(.:format)
  // function(id, options)
  red_tags_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"red_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// regional_dashboards => /dashboards/regional(.:format)
  // function(options)
  regional_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"regional",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// regional_sales_dashboards => /dashboards/regional_sales(.:format)
  // function(options)
  regional_sales_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"regional_sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// remove_ar_import => /ar_imports/:id/remove(.:format)
  // function(id, options)
  remove_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// report => /reports/:id(.:format)
  // function(id, options)
  report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports => /reports(.:format)
  // function(options)
  reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// resque_server => /admin/resque
  // function(options)
  resque_server_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"resque",false]]]]),
// reverse_merge_account => /accounts/:id/reverse_merge(.:format)
  // function(id, options)
  reverse_merge_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reverse_merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rolling_packaging_schedule_dashboards => /dashboards/rolling_packaging_schedule(.:format)
  // function(options)
  rolling_packaging_schedule_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"rolling_packaging_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {"host":"www.isithotinsfnow.local"}, [7,"/",false]),
// route_list_deliveryroute => /deliveryroutes/:id/route_list(.:format)
  // function(id, options)
  route_list_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"route_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_activity_dashboards => /dashboards/sales_activity(.:format)
  // function(options)
  sales_activity_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"sales_activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_chart_dashboards => /dashboards/sales_chart(.:format)
  // function(options)
  sales_chart_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"sales_chart",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_incentive_plan_dashboards => /dashboards/sales_incentive_plan(.:format)
  // function(options)
  sales_incentive_plan_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"sales_incentive_plan",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_placement_opportunities_dashboards => /dashboards/sales_placement_opportunities(.:format)
  // function(options)
  sales_placement_opportunities_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"sales_placement_opportunities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_region => /sales_regions/:id(.:format)
  // function(id, options)
  sales_region_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_regions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_regions => /sales_regions(.:format)
  // function(options)
  sales_regions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_regions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sales_rep_dashboards => /dashboards/sales_rep(.:format)
  // function(options)
  sales_rep_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"sales_rep",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_route => /sales_routes/:id(.:format)
  // function(id, options)
  sales_route_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_routes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_routes => /sales_routes(.:format)
  // function(options)
  sales_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sales_table_dashboards => /dashboards/sales_table(.:format)
  // function(options)
  sales_table_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"sales_table",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// saml_user_session => /users/saml/auth(.:format)
  // function(options)
  saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sap_order_batch => /order_batches/:id/sap(.:format)
  // function(id, options)
  sap_order_batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"order_batches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sap",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_to_netsuite_order => /orders/:id/send_to_netsuite(.:format)
  // function(id, options)
  send_to_netsuite_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_to_netsuite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_to_workwave_deliveryroute => /deliveryroutes/:id/send_to_workwave(.:format)
  // function(id, options)
  send_to_workwave_deliveryroute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_to_workwave",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_to_workwave_order => /orders/:id/send_to_workwave(.:format)
  // function(id, options)
  send_to_workwave_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_to_workwave",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_exception_categories => /service_exception_categories(.:format)
  // function(options)
  service_exception_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_exception_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// service_exception_category => /service_exception_categories/:id(.:format)
  // function(id, options)
  service_exception_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_exception_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// service_exceptions_dashboards => /dashboards/service_exceptions(.:format)
  // function(options)
  service_exceptions_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"service_exceptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ship_order => /orders/:id/ship(.:format)
  // function(id, options)
  ship_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ship",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// shipping_labels_order => /orders/:id/shipping_labels(.:format)
  // function(id, options)
  shipping_labels_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"shipping_labels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sku => /skus/:id(.:format)
  // function(id, options)
  sku_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"skus",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sku_tag => /sku_tags/:id(.:format)
  // function(id, options)
  sku_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sku_tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sku_tags => /sku_tags(.:format)
  // function(options)
  sku_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sku_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// skus => /skus(.:format)
  // function(options)
  skus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"skus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// skus_adjusting_order => /adjusting_orders/:id/skus(.:format)
  // function(id, options)
  skus_adjusting_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"skus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// statement => /statements/:id(.:format)
  // function(id, options)
  statement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// statement_account => /accounts/:id/statement(.:format)
  // function(id, options)
  statement_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"statement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// statements => /statements(.:format)
  // function(options)
  statements_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// stock_take => /stock_takes/:id(.:format)
  // function(id, options)
  stock_take_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stock_takes => /stock_takes(.:format)
  // function(options)
  stock_takes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// stop_impersonating_users => /users/stop_impersonating(.:format)
  // function(options)
  stop_impersonating_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"stop_impersonating",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tag => /tags/:id(.:format)
  // function(id, options)
  tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tags => /tags(.:format)
  // function(options)
  tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// total_ar_import => /ar_imports/:id/total(.:format)
  // function(id, options)
  total_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"total",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// transfer_orders_dashboards => /dashboards/transfer_orders(.:format)
  // function(options)
  transfer_orders_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"transfer_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// transfer_warehouses => /warehouses/transfer(.:format)
  // function(options)
  transfer_warehouses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"transfer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unapprove_order => /orders/:id/unapprove(.:format)
  // function(id, options)
  unapprove_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unapprove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unapprove_update_adjusting_order => /adjusting_orders/:id/unapprove_update(.:format)
  // function(id, options)
  unapprove_update_adjusting_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"adjusting_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unapprove_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unapprove_update_stock_take => /stock_takes/:id/unapprove_update(.:format)
  // function(id, options)
  unapprove_update_stock_take_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stock_takes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unapprove_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// uncomplete_order => /orders/:id/uncomplete(.:format)
  // function(id, options)
  uncomplete_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"uncomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unship_order => /orders/:id/unship(.:format)
  // function(id, options)
  unship_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unship",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_deliveryroute_details_order => /orders/:id/update_deliveryroute_details(.:format)
  // function(id, options)
  update_deliveryroute_details_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_deliveryroute_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_gps_deliveryroutes => /deliveryroutes/update_gps(.:format)
  // function(options)
  update_gps_deliveryroutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deliveryroutes",false],[2,[7,"/",false],[2,[6,"update_gps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_order_batch_details_order => /orders/:id/update_order_batch_details(.:format)
  // function(id, options)
  update_order_batch_details_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_order_batch_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users => /users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// validate_ar_import => /ar_imports/:id/validate(.:format)
  // function(id, options)
  validate_ar_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// vehicle => /vehicles/:id(.:format)
  // function(id, options)
  vehicle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"vehicles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// vehicles => /vehicles(.:format)
  // function(options)
  vehicles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"vehicles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// visit => /visits/:id(.:format)
  // function(id, options)
  visit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// visit_comment => /visits/:visit_id/comments/:id(.:format)
  // function(visit_id, id, options)
  visit_comment_path: Utils.route([["visit_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[2,[7,"/",false],[2,[3,"visit_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// visit_comments => /visits/:visit_id/comments(.:format)
  // function(visit_id, options)
  visit_comments_path: Utils.route([["visit_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[2,[7,"/",false],[2,[3,"visit_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// visits => /visits(.:format)
  // function(options)
  visits_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// warehouse => /warehouses/:id(.:format)
  // function(id, options)
  warehouse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// warehouses => /warehouses(.:format)
  // function(options)
  warehouses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// woods_consolidated_tracker_dashboards => /dashboards/woods_consolidated_tracker(.:format)
  // function(options)
  woods_consolidated_tracker_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"woods_consolidated_tracker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// year_over_year_comparison_dashboards => /dashboards/year_over_year_comparison(.:format)
  // function(options)
  year_over_year_comparison_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"year_over_year_comparison",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);
